import axios from 'axios'
const apiUrl = drupalSettings.danHotels.apiAddress
const clubApiUrl = drupalSettings.danHotels.clubApiAddress
const drupalEdanApiUrl = '/api/edan'

require('es6-promise').polyfill()
/*console.log('textsFromDrupal',drupalSettings.componentsInfo);*/

class data {
  constructor (method, data, api, headers, url) {
    this.url = url ? url : ((api === 'club' ? clubApiUrl : (api === 'drupal-edan' ? drupalEdanApiUrl : apiUrl)))
    this.api = api
    this.data = data
    this.headers = headers || {}
    this.method = method.toUpperCase()
    var self = this
    this.q = data
    return new Promise((resolve, reject) => {
      self.doConnect((res, err) => {
        if (err) {
          reject(err)
        }
        resolve(res)
      })
    })
  }

  doConnect (cb) {
    if (this.method === 'POST') {
      this.headers['Accept'] = 'application/json'
      this.headers['Content-Type'] = 'application/json'
      this.headers['Authorization'] = ((this.api === 'club' && drupalSettings.danHotelsEdanData && drupalSettings.danHotelsEdanData.user) ? 'Bearer ' + drupalSettings.danHotelsEdanData.user.token : null)
    }
    if (!this.url) {
      return cb(null, null)
    }

    axios({
      method: this.method,
      url: this.url,
      headers: this.headers,
      data: (this.api === 'drupal-edan' ? JSON.stringify(this.q) : {query: this.q})
    }).then(function (response) {
      return cb(response.data.data || response.data, null)
    })
      .catch(function (error) {
        console.log(error)
        return cb(null, error)
      })
  }

}

export default data
