import React from 'react';
import moment from 'moment';
import Dates from '../EditSelect/Dates.jsx';
import People from '../EditSelect/People.jsx';


export default class Deal extends React.Component {

    constructor(props) {
        super(props);
        this.texts = drupalSettings.componentsInfo;
        this.state = {
            flipped: false,
            bookDetails: false,
            focusedInput: null
        }

        this.showMoreDetails = this.showMoreDetails.bind(this);
        this.onDatesChange = this.onDatesChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);
        this.onOpenSelectFn = this.onOpenSelect.bind(this);
        this.onCloseSelectFn = this.onCloseSelect.bind(this)
        this.onChangePeople = this.onChangePeople.bind(this);
        this.search = this.search.bind(this);
    }

    getDateRange(date1, date2) {
        let lang = 'en';
        let start = moment(date1, moment.ISO_8601);
        let end = moment(date2, moment.ISO_8601);
        let dateRange = '';
        if (start.format('M.YY') == end.format('M.YY')) {
            dateRange += start.locale(lang).format("DD") + '-' + end.locale(lang).format("DD MMM, YYYY");
        } else {
            dateRange += start.locale(lang).format("DD MMM YYYY") + ' - ' + end.locale(lang).format("DD MMM YYYY");
        }
        return dateRange;
    }

    showMoreDetails(isShowMore) {
        this.setState({bookDetails: false, flipped: isShowMore, bookOpen: false});
    }

    bookNow(i, bookOpen) {
        this.setState({bookDetails: i, bookOpen});
        if (this.props.onChangeHeight) {
            this.props.onChangeHeight(true);
        }
    }

    onChangePeople(e) {
        if (e) {
            this.setState({rooms: e});
        }
    }

    onDatesChange(e) {
        if (e) {
            this.setState({
                startDate: e.startDate,
                endDate: e.endDate,
                focusedInput: null,
                errors: {errorCheckOutPrev: (e.endDate && e.startDate && moment(e.endDate, 'DD.MM.YYYY').isBefore(moment(e.startDate, 'DD.MM.YYYY')))}
            });
        }
    }

    onFocusChange(focusedInput) {
        this.setState({zIndex: (focusedInput ? 10 : 1)})
    }

    onOpenSelect() {
        this.setState({zIndex: 10})
    }
    onCloseSelect() {
        this.setState({zIndex: 1})
    }
    
    search() {
        const self = this;
        self.state.errors = {};
        let room1 = (self.state.rooms[0].adults && self.state.rooms[0].adults.value !== '0' || self.state.rooms[0].children && self.state.rooms[0].children.value !== '0' || self.state.rooms[0].infants && self.state.rooms[0].infants.value !== '0')
        if (self.state.startDate && self.state.endDate && room1) {
            if ((moment(self.state.endDate, 'DD.MM.YYYY').isBefore(moment(self.state.startDate, 'DD.MM.YYYY')))) {
                self.setState({errors: {errorCheckOutPrev: true}});
                return;
            }
            let rooms = [];
            const editSelectSettings = drupalSettings.danHotels.EditSelect;
            const queryParamskeys = editSelectSettings.queryParamskeys;
            let types = ['adults', 'children'];
            if (editSelectSettings.hotelSite.field_infants) {
                types.push('infants')
            }
            if (editSelectSettings.groupRoomsData == true) {
                let room = types.map(function (type) {
                    return `${queryParamskeys[type]}=${_.sumBy(self.state.rooms, (o) => { return o[type] ? parseInt(o[type].value) : 0 })}`;
                });
                rooms.push(room.join('&'));
            }
            else {
                for (var i in self.state.rooms) {
                    if (self.state.rooms[i].adults || self.state.rooms[i].children || self.state.rooms[i].infants) {
                        let room = types.map(function (type) {
                            return `${queryParamskeys[type].replace('*', i)}=${self.state.rooms[i][type] ? self.state.rooms[i][type].value : 0}`;
                        });
                        rooms.push(room.join('&'));
                    }
                }
            }

            let questionMark = '&';
            if (drupalSettings.danHotels.EditSelect.ReservationSiteUrl.indexOf('?') === -1) {
                questionMark = '?';
            }
            let roomsParam = editSelectSettings.sendRoomsParam ? `rooms=${self.state.rooms.length}&` : '';
            rooms = `${roomsParam}${rooms.join('&')}`
            let search = `${questionMark}${queryParamskeys.checkIn}=${self.state.startDate}&${queryParamskeys.checkout}=${self.state.endDate}&${rooms}`;
            window.location.href = editSelectSettings.ReservationSiteUrl + search;
            
        } else {
            let errors = {};
            if (!self.state.startDate) {
                errors.startDate = 'checkInNotSelected';
            }
            if (!self.state.endDate) {
                errors.endDate = 'checkOutNotSelected';
            }
            if (self.state.endDate && self.state.startDate && (moment(self.state.endDate, 'DD.MM.YYYY').isBefore(moment(self.state.startDate, 'DD.MM.YYYY')))) {
                errors.errorCheckOutPrev = true;
            }
            if (!room1) {
                errors.numPeople = 'numPeopleNotSelected';
            }
            self.setState({errors : errors});
        }
   
        
    }

    markup(content) {
        return { __html: content };
    }

    render() {
        return (
            <div className="deal" style={{zIndex: this.state.zIndex}} data-packageid={this.props.deal.packageID}>
                <div className={'flipDeal' + (this.state.flipped ? ' flipped' : ' regular')}>
                    {this.state.flipped ?
                        <div className="moreDetails">
                            <div className="closeBtn" onClick={() => this.showMoreDetails(false)}></div>
                            <div className="description" dangerouslySetInnerHTML={this.markup(this.props.deal.description)}></div>
                            { this.state.bookDetails || !this.props.deal.showBook ?
                                '' : <button className="bookBtn" onClick={() => this.bookNow(2, true)}><span>{this.texts.DealsByArea.bookNow}</span></button>
                            }
                            { this.state.bookDetails == 2 ?
                                <div className="bookDetails">
                                    <Dates autoFocus={true} focusedInput={this.state.focusedInput} onDatesChange={this.onDatesChange} onFocusChange={this.onFocusChange} startDate={this.state.startDate} endDate={this.state.endDate} errors={this.state.errors} deal={this.props.deal} isPlaceholder="true"/>
                                    <People onChangePeople={this.onChangePeople} hotelId={this.props.deal.hotelID} hotelsInfo={this.props.hotelsInfo} rooms={this.state.rooms} pax={[]} errors={this.state.errors} deal={this.props.deal} onOpenSelect={this.onOpenSelectFn} onCloseSelect={this.onCloseSelectFn}/>
                                    <div className="searchBtn" onClick={() => this.search()}>{this.texts.DealsByArea.search}</div>
                                </div>
                                : ''
                            }
                        </div> :
                        <div className="aboutDeal">
                            <div className="details">
                                {this.props.deal.hotelName ?
                                    <div className="date"><span>{this.props.deal.hotelName}</span></div> :
                                    null
                                }
                                <div className="name"><span>{this.props.deal.name}</span></div>
                                <div className="date"><span>{this.getDateRange(this.props.deal.startDate, this.props.deal.endDate)}</span></div>
                            </div>
                            <div className={"actions" + (!this.state.bookOpen ? ' close' : '') + (!this.props.deal.showBook ? ' auto' : '')}>
                                { this.state.bookDetails || !this.props.deal.showBook ?
                                    '' : <button className="bookBtn" onClick={() => this.bookNow(1, true)}><span>{this.texts.DealsByArea.bookNow}</span></button>
                                }
                                { this.state.bookDetails == 1 ?
                                    <div className="bookDetails">
                                        <div className="closeBtn" onClick={() => this.bookNow(0, false)}></div>
                                        <Dates autoFocus={true} focusedInput={this.state.focusedInput} onDatesChange={this.onDatesChange} onFocusChange={this.onFocusChange} startDate={this.state.startDate} endDate={this.state.endDate} errors={this.state.errors} deal={this.props.deal} isPlaceholder="true"/>
                                        <People onChangePeople={this.onChangePeople} hotelId={this.props.deal.hotelID} hotelsInfo={this.props.hotelsInfo} rooms={this.state.rooms} pax={[]} errors={this.state.errors} deal={this.props.deal} onOpenSelect={this.onOpenSelectFn} onCloseSelect={this.onCloseSelectFn}/>
                                        <div className="searchBtn" onClick={() => this.search()}>{this.texts.DealsByArea.search}</div>
                                    </div>
                                    : <a className="showMoreDetails" onClick={() => this.showMoreDetails(true)}><span>{this.texts.DealsByArea.moreDetails}</span></a>
                                }
                            </div>
                        </div>
                    }
                </div>
                
            </div>
        )
    }
}