import React from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import './dates.css';

const isMobile = window.matchMedia("(max-width: 767px)").matches;

export default class Days extends React.Component {

	constructor(props) {
		super(props);
		this.state = { datesInMinimum: [], lastDate: moment('2026-02-28', 'YYYY-MM-DD') };
		this.disabledLeft = true;
		this.formatDate = drupalSettings.danHotels.EditSelect.dateParamFormat;
		this.texts = drupalSettings.componentsInfo.EditSelect ? drupalSettings.componentsInfo.EditSelect.dates : drupalSettings.componentsInfo.reservationComponents.EditSelect.dates;
		this.errorTexts = drupalSettings.componentsInfo.OrderSelect ? drupalSettings.componentsInfo.OrderSelect.errors : drupalSettings.componentsInfo.reservationComponents.OrderSelect.errors;
		this.canGoLeft();
		this.canGoRight();
	}

	packageDays() {
		let days = {};
		let resDays = [], day;

		let weekArrival = [true, true, true, true, true, true, true];
		let weekValid = [true, true, true, true, true, true, true];
		// this.state.weekValid = weekValid;
		this.lastValidDate = null;
		let lastDate = (moment(this.props.deal.endDate));
		while (!this.lastValidDate) {
			if (weekValid[lastDate.weekday()]) {
				this.lastValidDate = lastDate;
			} else {
				lastDate.add(-1, 'days');
			}
		}

		var now = moment().startOf('day');
		let from = moment(this.props.deal.startDate, 'YYYY-MM-DD');
		from = from < now ? now : from;
		let to = moment(this.props.deal.endDate, 'YYYY-MM-DD').add(3, 'months');
		for (let d = from; d <= to; d.add(1, 'day')) {
			let weekday = moment(d, 'YYYY-MM-DD').weekday();
			day = {
				date: d.format(),
				available: true,
				closedForArrival: (moment(d) > this.lastValidDate ? true : !weekArrival[weekday]),
				closedForDeparture: false
			}
			resDays.push(day);
			days[moment(day.date, 'YYYY-MM-DD').format('YYYY-MM-DD')] = day;
		}
		if (!resDays.length) {
			this.setState({ days: null, lastDate: lastDate });
		}
		if (resDays.length) {
			lastDate = moment(resDays[resDays.length - 1].date, 'YYYY-MM');
		}
		let focusedInput = this.state.focusedInput || this.props.focusedInput || null;
		if (!focusedInput && this.props.autoFocus) {
			focusedInput = 'startDate';
			this.props.onFocusChange(focusedInput);
		}
		console.log("🚀 ~ Days ~ packageDays ~ lastDate:", lastDate)
		this.setState({days: days, firstDate: resDays[0], lastDate: lastDate, weekValid: weekValid, focusedInput});
	}

	onChange(e) {
		if (e) {
			this.state.selectedHotel = e;
			this.getPeople(e.value);
		}
	}

	componentWillMount() {
		require('./style.css');
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.deal && !this.state.isNotFirstTime || this.props.fullMode !== nextProps.fullMode) {
			this.setState({ isNotFirstTime: true })
			if (this.props.deal) {
				this.packageDays();
			}
			if (nextProps.startDate || nextProps.endDate) {

				this.onDatesChange({
					startDate: moment(nextProps.startDate, this.formatDate),
					endDate: moment(nextProps.endDate, this.formatDate)
				});
			}
			return false;
		}
		return true;
	}

	canGoLeft() {
		let self = this;
		setTimeout(function () {
			var a = document.getElementsByClassName("CalendarMonth__caption js-CalendarMonth__caption")
			var leftBtn = document.getElementsByClassName("DayPickerNavigation__prev")[0];
			var left = document.getElementsByClassName("arrow left")[0];
			if (a && a[1]) {
				let firstDate = moment();
				if (self.state.firstDate) {
					firstDate = moment(self.state.firstDate.date, 'YYYY-MM')
				}
				if (moment(a[1].innerText, 'MMMM YYYY') <= firstDate) {
					leftBtn.disabled = true;
					leftBtn.style.display = 'none';
					left.style["pointer-events"] = "none";
				} else {
					leftBtn.disabled = false;
					leftBtn.style.display = 'block';
					left.style["pointer-events"] = "auto";
				}
			}
		}, 10);
	}

	canGoRight() {
		let self = this;
		setTimeout(function () {
			var a = document.getElementsByClassName("CalendarMonth__caption js-CalendarMonth__caption")
			var rightBtn = document.getElementsByClassName("DayPickerNavigation__next")[0];
			var right = document.getElementsByClassName("arrow right")[0];
			if (a) {
				if (isMobile || self.props.deal) {
					a = a[1];
				} else {
					a = a[2];
				}
			}
			if (a) {
				let lastDate = moment();
				if (self.state.lastDate) {
					lastDate = self.state.lastDate;
				}
				if (moment(a.innerText, 'MMMM YYYY') >= lastDate) {
					rightBtn.disabled = true;
					rightBtn.style.display = 'none';
					right.style["pointer-events"] = "none";
				} else {
					rightBtn.disabled = false;
					rightBtn.style.display = 'block';
					right.style["pointer-events"] = "auto";
				}
			} else {
				self.disabledLeft = false;
			}
		}, 10);
	}

	onPrevMonthClick(m) {
		this.canGoLeft();
		this.canGoRight();
		this.checkFocusedInput();
	}

	onNextMonthClick(m) {
		this.canGoLeft();
		this.canGoRight();
		this.checkFocusedInput();
	}

	isDayBlocked(day) {
		if (!this.state.days || Object.keys(this.state.days).length === 0 || !this.state.days[day.format('YYYY-MM-DD')]) return false;
		var in30days = moment(this.state.startDate).add(30, 'days');
		let date = this.state.days[day.format('YYYY-MM-DD')];
		if (this.state.focusedInput === 'startDate' && (!date.available || date.closedForArrival)) {
			return true;
		}
		if (this.state.focusedInput === 'endDate') {
			if (day > this.state.startDate) {
				if (!this.state.firstUnAvailable) {
					if (!date.available) {
						this.state.firstUnAvailable = moment(date.date).add(1, 'days');
					}
				} else {
					if (this.state.firstUnAvailable <= day && day <= in30days) {
						return true;
					}
				}
				if (date.closedForDeparture) {
					return true;
				}
				if (this.props.deal && this.state.startDate) {
					let d1 = this.state.startDate.format('YYYY-MM-DD');
					let d2 = day.format('YYYY-MM-DD');
					let diff = moment(d2).startOf('day').diff(moment(d1).startOf('day'), 'days');
					if (diff < 7) {
						let valid = false;
						for (var i = 0; i < diff; i++) {
							if (this.state.weekValid?.[i + this.state.startDate.weekday()]) {
								valid = true;
							}
						}
						if (!valid) {
							return true;
						}
					}
				}
			}
			if (this.state.datesInMinimum.indexOf(day.format('YYYY-MM-DD')) !== -1) {
				return true;
			}
		}
		if (this.state.startDate && (day < this.state.startDate && date.closedForArrival)) {
			return true;
		}
		return false;
	}

	isOutsideRange(day) {
		if (day.isBefore(moment().format('YYYY-MM-DD'))) {
			return true;
		}
		if (!this.state.days || Object.keys(this.state.days).length === 0 || this.state.days[day.format('YYYY-MM-DD')]) {
			return false;
		}
		return true;
	}

	onDatesChange(dates, focus) {
		this.state.firstUnAvailable = null;
		this.state.datesInMinimum = [];
		let a;
		if (this.state.focusedInput === 'startDate') {
			dates.endDate = null;
		}
		if (dates.startDate < this.state.startDate && this.state.focusedInput === 'endDate' && !focus) {
			a = true;
			focus = 'endDate';
			this.setState({
				focusedInput: 'startDate'
			});
		}
		this.setState({
			startDate: dates.startDate,
			endDate: dates.endDate
		});
		this.props.onDatesChange({
			startDate: (dates.startDate ? dates.startDate.format(this.formatDate) : null),
			endDate: (dates.endDate ? dates.endDate.format(this.formatDate) : null)
		});
		let self = this;
		setTimeout(function () {
			if (a === true || focus) {
				self.setState({
					focusedInput: focus
				});
			}
		}, 2);

	}

	clearDates() {
		this.onDatesChange({ startDate: null, endDate: null }, 'startDate');
	}

	renderCalendarInfo() {
		return (
			<div className="calendarInfo">
				<div className="menu">
					<span><div className="square available"></div><div>{this.texts.available}</div></span>
					<span><div className="square noAvailability"></div><div>{this.texts.noAvailability}</div></span>
					<span onClick={this.clearDates.bind(this)}><div>{this.texts.clearDates}</div></span>
				</div>
			</div>
		)
	}

	renderDay(day) {
		var in30days = moment(this.state.startDate).add(30, 'days');
		return (this.state.focusedInput === 'endDate' && ((this.state.firstUnAvailable && this.state.firstUnAvailable <= day && day <= in30days) || (this.state.datesInMinimum.indexOf(day.format('YYYY-MM-DD')) !== -1)) ? '-' + day.format('D') + '-' : day.format('D'));
	}

	isDayHighlighted(day) {
		if (this.state.startDate && this.state.focusedInput === 'endDate' && day <= this.state.startDate) {
			return true;
		}
		return false;
	}

	handleMouseOver(e) {
		if (this.state.focusedInput === 'endDate') {
			if (e.className.indexOf('CalendarDay--highlighted-calendar') > -1) {
				let a = document.getElementsByClassName('DateInput__display-text');
				if (a && a[0]) {
					a[0].classList.add('DateInput__display-text--focused');
					a[1].classList.remove('DateInput__display-text--focused');
				}
				let b = document.getElementsByClassName('DateInput');
				if (b && b[0]) {
					b[0].classList.add('DateInput--with-caret');
					b[1].classList.remove('DateInput--with-caret');
				}
			} else {
				let a = document.getElementsByClassName('DateInput__display-text');
				if (a && a[0]) {
					a[0].classList.remove('DateInput__display-text--focused');
					a[1].classList.add('DateInput__display-text--focused');
				}
				let b = document.getElementsByClassName('DateInput');
				if (b && b[0]) {
					b[0].classList.remove('DateInput--with-caret');
					b[1].classList.add('DateInput--with-caret');
				}
			}
		}
	}

	checkFocusedInput() {
		let self = this;
		setTimeout(function () {
			let calendar = document.getElementsByClassName('DateRangePicker__picker')[0];
			if (calendar) {
				if (self.state.focusedInput === 'endDate') {
					calendar.classList.add('endDate');
					calendar.classList.remove('startDate');
				} else {
					calendar.classList.add('startDate');
					calendar.classList.remove('endDate');
				}
				if (!isMobile) {
					let parent = calendar.parentElement;
					let a = calendar.getElementsByClassName('DayPicker')[0];
					if ((self.props.lang === 'heb' ? parent.getBoundingClientRect().right : (window.innerWidth - parent.getBoundingClientRect().left)) - a.getBoundingClientRect().width < 0) {
						calendar.parentElement.parentElement.parentElement.classList.add('move')
					}
				}
			}

			var el = document.getElementsByClassName("CalendarDay--valid");
			if (el) {
				for (var i = 0; i < el.length; i++) {
					el[i].removeEventListener("mouseover", self.handleMouseOver.bind(self, el[i]), false);
					if (self.state.focusedInput === 'endDate') {
						el[i].addEventListener("mouseover", self.handleMouseOver.bind(self, el[i]), false);
					}
				}
			}
		}, 10);
	}

	handleForceFocus() {
		let a = document.getElementsByClassName('DateInput__display-text');
		if (a && a[0]) {
			a[0].classList.remove('DateInput__display-text--focused');
			a[1].classList.add('DateInput__display-text--focused');
		}
		let b = document.getElementsByClassName('DateInput');
		if (b && b[0]) {
			b[0].classList.remove('DateInput--with-caret');
			b[1].classList.add('DateInput--with-caret');
		}
	}

	componentDidMount() {
		let a = document.getElementById('endDate');
		if (a && a) {
			a.addEventListener("mousedown", this.handleForceFocus.bind(this), false);
		}
		let b;
		if (this.props.deal) {
			if (isMobile) {
				b = document.getElementsByClassName('flipDeal');
			} else {
				b = document.getElementsByClassName('bookDetails')[0].getElementsByClassName('DateRangePicker');
			}
		} else {
			b = document.getElementsByClassName('DateRangePicker');
		}
		if (b && b[0]) {
			let size;
			if (isMobile && this.props.deal) {
				size = (b[0].offsetWidth - 48) / 7;
			} else {
				size = (b[0].offsetWidth - (isMobile || this.props.asMobile || this.props.deal ? 48 : 74)) / (isMobile || this.props.asMobile || this.props.deal || this.props.format === 'mini' ? 7 : 14);
			}
			this.setState({ daySize: Math.round(size) });
		}
	}

	onFocusChange(focusedInput) {
		if (this.props.onFocusChange) {
			this.props.onFocusChange(focusedInput);
		}
		this.setState({ focusedInput });
		if (this.props.format === 'mini') {
			this.props.handleFullMode('dates');
		}
	}

	initialVisibleMonth() {
		if (this.state.startDate && this.state.startDate.isSameOrAfter(moment().format('YYYY-MM-DD'))) {
			return this.state.startDate;
		}
		if (this.state.firstDate) {
			return moment(this.state.firstDate.date, 'YYYY-MM-DD');
		}
		return moment();
	}

	render() {
		this.checkFocusedInput();
		this.canGoLeft();
		this.canGoRight();
		let err = '';
		if (this.props.errors && this.props.errors.startDate) {
			err = err + ' startDateErr';
		}
		if (this.props.errors && this.props.errors.endDate) {
			err = err + ' endDateErr';
		}
		return (
			<div className={"dates" + (this.props.errors && this.props.errors.errorCheckOutPrev ? ' error' : err)}>
				{this.props.isPlaceholder ? '' :
					<div>
						<span>{this.texts.checkIn}</span>
						<span>{this.texts.checkOut}</span>
					</div>
				}
				<DateRangePicker
					startDate={this.state.startDate} // momentPropTypes.momentObj or null,
					endDate={this.state.endDate} // momentPropTypes.momentObj or null,
					onDatesChange={this.onDatesChange.bind(this)} // PropTypes.func.isRequired,
					focusedInput={this.state.focusedInput || this.props.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
					onFocusChange={this.onFocusChange.bind(this)}
					isRTL={this.props.lang == 'heb'}
					anchorDirection={(this.props.lang == 'heb' ? 'right' : 'left')}
					numberOfMonths={(isMobile ? 1 : 2)}
					renderCalendarInfo={this.renderCalendarInfo.bind(this)}
					isDayBlocked={this.isDayBlocked.bind(this)}
					isOutsideRange={this.isOutsideRange.bind(this)}
					minimumNights={1}
					navPrev={<span className="arrow left"></span>}
					navNext={<span className="arrow right"></span>}
					customArrowIcon={(this.props.format === 'mini' && !isMobile ? <div><span className="arrow right"></span></div> : <div></div>)}
					displayFormat={(isMobile && this.props.deal ? "DD/MM/YY ddd" : "DD/MM/YY dddd")}
					endDatePlaceholderText={this.props.isPlaceholder || this.props.format === 'mini' ? this.texts.checkOut : ""}
					startDatePlaceholderText={this.props.isPlaceholder || this.props.format === 'mini' ? this.texts.checkIn : ""}
					onPrevMonthClick={this.onPrevMonthClick.bind(this)}
					onNextMonthClick={this.onNextMonthClick.bind(this)}
					renderDay={day => (this.renderDay(day))}
					isDayHighlighted={this.isDayHighlighted.bind(this)}
					daySize={this.state.daySize}
					initialVisibleMonth={this.initialVisibleMonth.bind(this)}
					orientation={'horizontal'} />
				<div className={(this.props.errors && (this.props.errors.endDate || this.props.errors.startDate || this.props.errors.errorCheckOutPrev) ? "errors-block" : "errors-empty")}>
					{this.props.errors && this.props.errors.startDate ?
						<span className="errors">{this.errorTexts[this.props.errors.startDate]}</span> :
						null
					}
					{this.props.errors && this.props.errors.endDate ?
						<span className="errors">{this.errorTexts[this.props.errors.endDate]}</span> :
						null
					}
					{this.props.errors && this.props.errors.errorCheckOutPrev ?
						<span className="errors">{this.errorTexts.checkOutBeforeCheckIn}</span> :
						null
					}
				</div>
			</div>
		);
	}
}
