import React from 'react';
import SelectBox from '../../components/SelectBox';

export default class Poeple extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.texts = drupalSettings.componentsInfo.EditSelect?.people || drupalSettings.componentsInfo.reservationComponents.EditSelect.people;
        this.labels =drupalSettings.componentsInfo.OrderSelect?.people || drupalSettings.componentsInfo.reservationComponents.OrderSelect.people;
        this.errorTexts = drupalSettings.componentsInfo.OrderSelect?.errors || drupalSettings.componentsInfo.reservationComponents.OrderSelect.errors;
        this.onChangePeople = this.onChangePeople.bind(this);
        this.optionsClass = `peopleOptions ${this.props.deal && 'deal'}`;
    }

    onChangePeople(e) {
        e = e.target ? e.target : e;
        if (e) {
            if (e.value !== 'more' && e.value !== 'multiple') {
                let rooms = [{}];
                let val = JSON.parse(e.value);
                for (var i in val) {
                    rooms[0][i] = {
                        label: val[i].toString(),
                        value: val[i].toString()
                    };
                }
                this.props.onChangePeople(rooms);
            } else {
                if (e.value === 'multiple' && this.props.rooms.length < 2) {
                    this.addAnotherRoom();
                }
            }
            this.setState({
                selectedPeople: e,
                more: (e.value === 'more' || e.value === 'multiple'),

            });
        }
    }

    onChangeNumber(index, type, e) {
        if (e.target) {
            let rooms = this.props.rooms;
            rooms[index][type] = e.target;
            this.props.onChangePeople(rooms);
        }
    }

    addAnotherRoom() {
        let info = this.props.hotelsInfo[0];
        let rooms = this.props.rooms;
        rooms[rooms.length] = {};
        let e = this.get_number_of_people_option(info.field_default_option);
        let val = (e && e.value ? JSON.parse(e.value) : []);
        for (var i in val) {
            rooms[rooms.length - 1][i] = {
                label: val[i].toString(),
                value: val[i].toString()
            };
        }
        this.props.onChangePeople(rooms);
    }

    removeRoom(index) {
        let rooms = this.props.rooms;
        rooms.splice(index, 1);
        this.props.onChangePeople(rooms);
    }

    number_of(field) {
        if (field) {
            return(field.split(', ').map(function(num) {
                return {'label': num, 'value': num};
            }));
        } else {
            return [];
        }
    }

    number_of_people(field) {
        if (field === '') {
            return [];
        }
        let self = this;
        if (field) {
            return(field.split(', ').map(function(num) {
                return self.get_number_of_people_option(num);
            }));
        } else {
            return [];
        }
    }

    get_number_of_people_option(num) {
        let label = [];
        let value = {};
        if (num) {
            num = num.split(',');
            if (num[0]) {
                value.adults = num[0];
                if (num[0] !== '0') {
                    label.push(num[0] + ' ' + (num[0] === '1' ? this.labels['adult'] : this.labels['adults']));
                }
            }
            if (num[1]) {
                value.children = num[1];
                if (num[1] !== '0') {
                    label.push(num[1] + ' ' + (num[1] === '1' ? this.labels['child'] : this.labels['children']));
                }
            }
            if (num[2]) {
                value.infants = num[2];
                if (num[2] !== '0') {
                    label.push(num[2] + ' ' + (num[2] === '1' ? this.labels['infant'] : this.labels['infants']));
                }
            }
            return {'label': label.join(' + '), 'value': JSON.stringify(value)};
        } else {
            return [];
        }
    }

    getPeople(hotelsInfo, pax) {
        let self = this;
        let info = hotelsInfo[0];
        let people = self.number_of_people(info.field_number_of_people);
        people.push({'label': self.texts.moreOptions, 'value': 'more'});
        people.push({'label': self.texts.multipleRoom, 'value': 'multiple'});
        let defaultOption = self.get_number_of_people_option(info.field_default_option);
        if (pax && pax.length) {
            if (pax.length === 1) {
                defaultOption = self.get_number_of_people_option([pax[0].Adults, pax[0].Children, pax[0].Infants].join(','));
            } else {
                let rooms = [];
                for (var i in pax) {
                    rooms[i] = {};
                    for (var j in pax[i]) {
                        rooms[i][j.toLowerCase()] = {label: pax[i][j], value: pax[i][j]};
                    }
                }
                self.props.onChangePeople(rooms);
                
                self.state.selectedPeople = {'label': self.texts.multipleRoom, 'value': 'multiple'};
                setTimeout(function() {
                    self.onChangePeople(self.state.selectedPeople);
                }, 10);
            }
        }
        if (!self.state.selectedPeople && info.field_default_option && info.field_default_option !== '') {
            let existsInPeople = people.find(function(p) {
                return p.label === defaultOption.label;
            })
            self.onChangePeople(defaultOption);
            if (!existsInPeople) {
                if(!this.props.deal) {
                    self.state.selectedPeople = {'label': self.texts.moreOptions, 'value': 'more'}; 
                }
                   
                self.onChangePeople(self.state.selectedPeople);
            }
        }
        const peopleOptions = ['adults', 'children'];
        if (info.field_infants) peopleOptions.push('infants');
        setTimeout(function() {
            self.setState({people: people,
                adults: self.number_of(info.field_adults),
                children: self.number_of(info.field_children),
                infants: self.number_of(info.field_infants),
                peopleOptions: peopleOptions 
            });
        }, 10);
    }

    componentWillMount() {
        this.getPeople([drupalSettings.danHotels.EditSelect.hotelSite], this.props.pax);
    }

    handleKeyDown(func, index, e) {
        if (e.keyCode == 13) {
            this[func](index, e);
        }
    }

    render() {
        return (
            <div className={"people" + (this.props.errors && this.props.errors.numPeople ? ' error' : '')}>
                <div className="options">
                    <SelectBox
                        options={this.state.people}
                        onChange={this.onChangePeople}
                        value={this.state.selectedPeople?.value}
                        placeholder={this.texts.label}
                        menuClass={this.optionsClass}
                    />
                </div>
                {this.props.errors && this.props.errors.numPeople ?
                    <div className="errors">{this.errorTexts[this.props.errors.numPeople]}</div> :
                    null
                }
                {this.state.more ? 
                    <div className="moreOptions">
                        {this.props.rooms.map((item, index) => {
                            return <div key={index} className={"room" + (this.props.rooms.length-1 === index ? ' last' : '')}>
                                <div>{this.texts.room} #{index+1}</div>
                                {this.state.peopleOptions.map((type, i) => {
                                    return <div key={i}>
                                                <div className="number">
                                                    <SelectBox
                                                        label={this.texts[type]}
                                                        options={this.state[type]}
                                                        onChange={this.onChangeNumber.bind(this, index, type)}
                                                        value={this.props.rooms[index][type]?.value}
                                                        idInner={`${type}Room${index}Select`}
                                                    />
                                                </div>
                                    </div>
                                })}
                                <div onClick={this.removeRoom.bind(this, index)} className="clear" tabIndex={300 + index*10 + 4} onKeyDown={this.handleKeyDown.bind(this, 'removeRoom', index)}>X</div>
                            </div>
                        })}
                        {this.props.rooms.length < 2 ?
                            <span className="addAnother" onClick={this.addAnotherRoom.bind(this)} tabIndex={399} onKeyDown={this.handleKeyDown.bind(this, 'addAnotherRoom', 0)}>{this.texts.addAnotherRoom}</span> :
                            null
                        }
                    </div> :
                    null
                }
            </div>
        );
    }
}