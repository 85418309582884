"use strict";

import './widgets/Packages/HotelPackages/HotelPackages.jsx';

import './widgets/EditSelect/EditSelect.jsx';
import './widgets/SelectBoxLinks/SelectBoxLinks.jsx';

import ReactDOM from "react-dom";
import  React from "react";



// <run-react component="ReactComponentName" callback="setDataProps()"></run-react>
function RunReact() {
  return Reflect.construct(HTMLElement, [], this.constructor);
}
RunReact.prototype = Object.create(HTMLElement.prototype);
RunReact.prototype.constructor = RunReact;
Object.setPrototypeOf(RunReact, HTMLElement);
RunReact.prototype.connectedCallback = function() {
        var callback = Function("return " + this.getAttribute('callback'))();
        var reactComponent = Function("return Widgets." + this.getAttribute('component'))();
        ReactDOM.render(React.createElement(reactComponent, callback), this);
    }
customElements.define('run-react', RunReact);
