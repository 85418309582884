import React from 'react';
import SelectBox from '../../components/SelectBox';

export default class SelectBoxLinks extends React.Component {
    constructor(props) {
        super(props);
        this.lang = drupalSettings.danHotels.lang;
        require('./style.css');

        let objects = JSON.parse(JSON.stringify(drupalSettings.danHotels.SelectBoxLinks.data));
        Object.keys(objects).forEach(function (key) {
            objects[key].value = objects[key].url;
            objects[key].class = objects[key].class;
            delete objects[key].url;
        });
        let selectedObj = {
            label: drupalSettings.danHotels.SelectBoxLinks.selected.label,
            value: drupalSettings.danHotels.SelectBoxLinks.selected.url
        };
        this.state = {
            options: objects,
            selectedObj: selectedObj,
            placeHolder: drupalSettings.danHotels.SelectBoxLinks.placeHolder,
            actionClass: 'down',
            className: 'select-box-links' + ' ' + drupalSettings.danHotels.SelectBoxLinks.class
        };
        this.onChange = this.onChange.bind(this);
        this.changeArrowAction = this.changeArrowAction.bind(this);
    };
    changeArrowAction(action) {
        this.setState({ actionClass: action });
    }

    onChange(e) {
        if (e.target && e.target.value) {
            this.setState({selectedObj:e.target});
            window.location = e.target.value; // redirect
        }
    }

    render() {
        return (
            <div className={this.state.className}>
                <SelectBox
                    options={this.state.options}
                    onChange={this.onChange}
                    value={this.state.selectedObj.value}
                    label={this.state.placeHolder}
                />
            </div>
        );
    }

}
Widgets.SelectBoxLinks = SelectBoxLinks;