import React from 'react';
import Dates from './Dates.jsx';
import People from './People.jsx';
import EdanClub from './EdanClub.jsx';
import moment from 'moment';

const isMobile = window.matchMedia("(max-width: 767px)").matches;

export default class EditSelect extends React.Component {

    constructor(props) {
        super(props);
        this.hotelSite = drupalSettings.danHotels.EditSelect.hotelSite;
        this.attachedSettings = drupalSettings.danHotels.EditSelect;
        require('./style.css');
        const fullmode = sessionStorage.getItem('fullmode');
        sessionStorage.removeItem('fullmode')

        this.state = {
            params: this.props.orderParams[0],
            rooms: [{}],
            edit: this.props.SelectedHotelID ? true : false,
            editMode: fullmode || this.props.editMode,
            fullMode: fullmode,
            hotelsInfo: [this.hotelSite]
        };

        this.texts = drupalSettings.componentsInfo.EditSelect || drupalSettings.componentsInfo.reservationComponents.EditSelect;
        this.errorTexts = drupalSettings.componentsInfo.OrderSelect ? drupalSettings.componentsInfo.OrderSelect.errors : drupalSettings.componentsInfo.reservationComponents.OrderSelect.errors;
        this.onChangePeople = this.onChangePeople.bind(this);
        this.onDatesChange = this.onDatesChange.bind(this);
        this.show = this.show.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleFullMode = this.handleFullMode.bind(this);
    }

    onChangePeople(e) {
        if (e) {
            this.setState({ rooms: e });
        }
    }

    onDatesChange(e) {
        if (e) {
            this.setState({
                startDate: e.startDate,
                endDate: e.endDate,
                focusedInput: null,
                errors: { errorCheckOutPrev: (e.endDate && e.startDate && moment(e.endDate, 'DD.MM.YYYY').isBefore(moment(e.startDate, 'DD.MM.YYYY'))) }
            });
        }
    }

    show(type) {
        this.setState({ edanBlockType: type });
    }

    handleClick(type, e) {
        if (e.keyCode == 13) {
            this.show(type);
        }
    }

    updateRates(e) {
        this.handleFullMode();
        let self = this;
        self.state.errors = {};
        let room1 = (self.state.rooms[0].adults && self.state.rooms[0].adults.value !== '0' || self.state.rooms[0].children && self.state.rooms[0].children.value !== '0' || self.state.rooms[0].infants && self.state.rooms[0].infants.value !== '0')
        if (self.state.startDate && self.state.endDate && room1) {
            if (self.state.endDate && self.state.startDate && (moment(self.state.endDate, 'DD.MM.YYYY').isBefore(moment(self.state.startDate, 'DD.MM.YYYY')))) {
                self.setState({ errors: { errorCheckOutPrev: true } });
                return;
            }
            let rooms = [];
            const queryParamskeys = this.attachedSettings.queryParamskeys;
            let types = ['adults', 'children'];
            if (this.hotelSite.field_infants) {
                types.push('infants')
            }
            if (this.attachedSettings.groupRoomsData == true) {
                let room = types.map(function (type) {
                    return `${queryParamskeys[type]}=${_.sumBy(self.state.rooms, (o) => { return o[type] ? parseInt(o[type].value) : 0 })}`;
                });
                rooms.push(room.join('&'));
            }
            else {
                for (var i in self.state.rooms) {
                    if (self.state.rooms[i].adults || self.state.rooms[i].children || self.state.rooms[i].infants) {
                        let room = types.map(function (type) {
                            return `${queryParamskeys[type].replace('*', i)}=${self.state.rooms[i][type] ? self.state.rooms[i][type].value : 0}`;
                        });
                        rooms.push(room.join('&'));
                    }
                }
            }

            let questionMark = '&';
            if (this.attachedSettings.ReservationSiteUrl.indexOf('?') === -1) {
                questionMark = '?';
            }
            let roomsParam = this.attachedSettings.sendRoomsParam ? `rooms=${self.state.rooms.length}&` : '';
            rooms = `${roomsParam}${rooms.join('&')}`
            let search = `${questionMark}${queryParamskeys.checkIn}=${self.state.startDate}&${queryParamskeys.checkout}=${self.state.endDate}&${rooms}`;
            window.location.href = this.attachedSettings.ReservationSiteUrl + search;
        } else {
            let errors = {};
            if (!self.state.startDate) {
                errors.startDate = 'checkInNotSelected';
            }
            if (!self.state.endDate) {
                errors.endDate = 'checkOutNotSelected';
            }
            if (self.state.endDate && self.state.startDate && (moment(self.state.endDate, 'DD.MM.YYYY').isBefore(moment(self.state.startDate, 'DD.MM.YYYY')))) {
                errors.errorCheckOutPrev = true;
            }
            if (!room1) {
                errors.numPeople = 'numPeopleNotSelected';
            }
            self.setState({ errors: errors });
        }
    }

    handleKeyDown(func, e) {
        if (e.keyCode == 13) {
            this[func](e);
        }
    }

    componentDidMount() {
        setTimeout(function () {
            var sDate = document.getElementById('startDate');
            if (sDate) {
                sDate.tabIndex = 0;
            }
            var eDate = document.getElementById('endDate');
            if (eDate) {
                eDate.tabIndex = 0;
            }
        }, 10);
    }

    handleFullMode(defaultOpen) {
        if (this.props.format === 'mini' && !this.state.fullMode) {
            if (!this.state.params.SelectedHotelID) {
                defaultOpen = 'hotels';
            }
            if (defaultOpen === 'dates') {
                defaultOpen = null;
                this.state.focusedInput = null;
                this.state.forceOpen = true;
            }
            this.setState({ fullMode: true, defaultOpen, editMode: true });
        }
    }

    openDetails() {
        if (isMobile && !this.state.editMode) {
            this.setState({ editMode: true });
        }
    }

    render() {
        return (
            <div>
                {!this.props.format || this.state.fullMode ?
                    <div tabIndex="0" className={"editReservationContainer" + (!this.state.editMode ? ' closed' : ' open') + (this.props.asMobile ? ' asMobile' : '')} key={'reg'} style={{ backgroundColor: this.props.style && this.props.format === 'mini' ? this.props.style.bg_color : '' }}>
                        <div>
                            <div className="editReservationHeader"><div><span>{this.texts.title}</span></div></div>
                        </div>
                        <div className="editReservationDetails">
                            <div className="hotelName"><span>{this.hotelSite.name}</span></div>
                            <Dates autoFocus={(!this.state.params.SelectedHotelID || this.state.forceOpen) ? true : false} focusedInput={this.state.focusedInput === 'startDate' ? 'startDate' : null} onDatesChange={this.onDatesChange} hotelId={this.state.selectedHotel ? this.state.selectedHotel.value : null} startDate={this.state.startDate || this.state.params.CheckIn} endDate={this.state.endDate || this.state.params.CheckOut} errors={this.state.errors} lang={this.state.params.Lang} fullMode={this.state.fullMode} asMobile={this.props.asMobile} />
                            <People onChangePeople={this.onChangePeople} hotelId={this.state.selectedHotel ? this.state.selectedHotel.value : null} hotelsInfo={this.state.hotelsInfo} rooms={this.state.rooms} pax={this.state.params.pax} errors={this.state.errors} fullMode={this.state.fullMode} />
                            <div className="updateRates" onClick={this.updateRates.bind(this)} tabIndex="0" onKeyDown={this.handleKeyDown.bind(this, 'updateRates')}><span>{this.state.edit ? this.texts.button.upgrade : this.texts.button.new}</span></div>
                        </div>
                        {isMobile || this.props.asMobile ? <div></div> :
                            <EdanClub show={this.show} onClick={this.handleClick} lang={this.state.params.Lang} />
                        }
                    </div> :
                    <div tabIndex="0" className={"editReservationContainer mini" + ((!this.state.editMode && isMobile) ? ' closed' : ' open') + (this.props.asMobile ? ' asMobile' : '') + (this.state.fullMode ? ' fullMode' : '')} style={{ backgroundColor: this.props.style ? this.props.style.bg_color : '' }}>
                        <div onClick={this.openDetails.bind(this)}>
                            <div className="editReservationHeader">
                                <div><span>{this.texts.title}</span></div>
                                <div className="arrow down"></div>
                            </div>
                        </div>
                        <div className="editReservationDetails">
                            <div>
                                <div className="hotelName"><span>{this.hotelSite.name}</span></div>
                                <Dates focusedInput={this.state.focusedInput === 'startDate' ? 'startDate' : null} onDatesChange={this.onDatesChange} hotelId={this.state.selectedHotel ? this.state.selectedHotel.value : null} startDate={this.state.startDate || this.state.params.CheckIn} endDate={this.state.endDate || this.state.params.CheckOut} errors={this.state.errors} lang={this.state.params.Lang} format="mini" handleFullMode={this.handleFullMode} />
                                {!isMobile || !this.state.fullMode ?
                                    <div className="updateRates" onClick={this.updateRates.bind(this)} tabIndex="0" onKeyDown={this.handleKeyDown.bind(this, 'updateRates')}><span>{this.state.edit ? this.texts.button.upgrade : this.texts.button.new}</span></div> :
                                    null
                                }
                            </div>
                            <div>
                                <People onChangePeople={this.onChangePeople} hotelId={this.state.selectedHotel ? this.state.selectedHotel.value : null} hotelsInfo={this.state.hotelsInfo} rooms={this.state.rooms} pax={this.state.params.pax} errors={this.state.errors} />
                                {isMobile ?
                                    <div className="updateRates" onClick={this.updateRates.bind(this)} tabIndex="0" onKeyDown={this.handleKeyDown.bind(this, 'updateRates')}><span>{this.state.edit ? this.texts.button.upgrade : this.texts.button.new}</span></div> :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

Widgets.EditSelect = EditSelect;