import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";

import "./style.css";

export default function SelectBox(props) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    props.onClose && props.onClose();
  };
  const handleOpen = () => {
    setOpen(true);
    props.onOpen && props.onOpen();
  };
  return (
    <FormControl focused={props.autoFocus} variant={props.variant || 'outlined'} fullWidth className="selectBox" error={props.errorText && true} required={props.required}>
      {props.label && <InputLabel id={props.idInner && `${props.idInner}-label`}>{props.label}</InputLabel>}
      {props.placeholder && !props.value && <InputLabel id={props.idInner && `${props.idInner}-label`} disableAnimation shrink={false} focused={false}>{props.placeholder}</InputLabel>}
      <Select
        id={props.idInner}
        name={props.name}
        labelId={props.idInner && `${props.idInner}-label`}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        onBlur={props.onBlur}
        onChange={props.onChange}
        className="selectInput"
        defaultValue={props.value}
        value={props.value || ''}
        MenuProps={{ classes: { root: props.menuClass } }}
      >
        {props.options ?
          props.options.map((option, index) => {
            return (
              <MenuItem key={index} value={option.value} className={option.class} disabled={option.disabled}>
                <span dangerouslySetInnerHTML={{ __html: option.label }} aria-label={option.ariaLabel} />
              </MenuItem>
            );
          }) :
          <MenuItem className='no-options' disabled value="">{'No results Found'}</MenuItem>
        }
      </Select>
      {props.errorText && <FormHelperText>{props.errorText}</FormHelperText>}
    </FormControl>
  );
}

