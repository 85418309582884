import React from 'react';
import Deal from '../Deal.jsx';
import DATA from '../../data';
import _ from 'lodash';
import Carousel from 'nuka-carousel';

const isMobile = window.matchMedia("(max-width: 767px)").matches;
require('../style.css')
class HotelPackages extends React.Component {
    constructor(props) {
        super(props);
        this.lang = drupalSettings.danHotels.lang;
        this.texts = drupalSettings.componentsInfo;
        this.changeHeight = this.changeHeight.bind(this);
        this.state = ({
            packages: [],
            hotelsInfo: [drupalSettings.danHotels.EditSelect.hotelSite],
        });
    }

    componentWillMount() {
        this.load();
    }

    load() {
        var self = this;
        const url = `/data/deals/${drupalSettings.danHotels.hotelId}?_format=json&category=${drupalSettings.danHotels.categoryID || 'all'}&lang=${document.documentElement.lang}`;
        new DATA('get', null, null, null, url).then(res => {
            if(res.error) {

            } else {
                let packages = _.sortBy(res, 'weight');
                packages = packages.map(i => {
                    const obj = i;
                    obj.showBook = obj.showBook === "True" ? true : false;
                    return obj;
                })
                if (packages.length > 6 && !isMobile) {
                    self.setState({packages: _.chunk(packages, 6), carousel: true});
                } else {
                    self.setState({packages: packages});
                }
            }
        })
    }

    matchMobileWidth() {
        var mq = window.matchMedia("(max-width: 767px)");
        return mq.matches;
    }

    changeHeight(large) {
        this.setState({ large });
    }

    markup(content) {
        return { __html: content };
    }

    render() { 
        return (
            <div className="hotelPackages packagesGroup">
                {this.state.packages.length ?
                    <div className="gradient"></div> :
                    null
                }
                <div className={"deals" + (this.state.large ? ' large' : '')}>
                    {this.state.carousel ?
                        <Carousel swiping={true} wrapAround={true}>
                            {this.state.packages.map((packages) => {
                                return <div className="deals hotelPackagesCarousel">
                                    {packages.map((item, packageIndex) => {
                                        return <Deal key={packageIndex} deal={item} dealIndex={packageIndex} lang={this.lang} hotelsInfo={this.state.hotelsInfo} dealsLength={this.state.packages.length} onChangeHeight={this.changeHeight}/>
                                    })}
                                    {packages.length % 3  == 2 ?
                                        <div className="placeHolder"></div> : ''
                                    }
                                </div>
                            })}
                        </Carousel> :
                        <div className="deals onePackage">
                            {this.state.packages.map((item, packageIndex) => {
                                return <Deal key={packageIndex} deal={item} dealIndex={packageIndex} lang={this.lang} hotelsInfo={this.state.hotelsInfo} dealsLength={this.state.packages.length}/>
                            })}
                            { this.state.packages.length % 3  == 2 ?
                                <div className="placeHolder"></div> : ''
                            }
                        </div>
                    }
                    { this.state.packages.length ? '' :
                        <div className="noDeals"><span>{this.texts.DealsByArea.noResults}</span></div>
                    }
                </div>
            </div>
        )
    }
}

Widgets.HotelPackages = HotelPackages;