import React from 'react';

export default class EdanClub extends React.Component {

	constructor(props) {
		super(props);
    require('../Reservation/style.css');
    require('./style.css');
		this.state = {ClubMemberNumber: sessionStorage.getItem('ClubMemberNumber') || ''};
    this.texts = (drupalSettings.componentsInfo.OrderResults ? drupalSettings.componentsInfo.OrderResults : drupalSettings.componentsInfo.reservationComponents.OrderResults);
	}
	handleKeyDown(func, e) {
    if (e.keyCode == 13) {
        this[func](e);
    }
  }
  numberWithCommas(x) {
    if (!x) return 0;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

	render() {
		return (
			<div className="edanClub">
			    <div className={"clubSticky show"}>
            <div>
              <div className="title"><span>{this.texts['E-dan'].editBlock.title}</span></div>
              <div className="secondaryTitle">
                <div><span>{this.texts['E-dan'].editBlock.secondaryTitle}</span></div>
              </div>
              <div className="iconsRow">
                <div tabIndex={0}>
                  {this.texts['E-dan'].data['5Off'].tooltip
                    ? <div className="tooltiptext"><span>{this.texts['E-dan'].data['5Off'].tooltip}</span></div>
                    : null
                  }
                  <div className="bgImg">
                    <img src={this.texts['E-dan'].data['5Off'].icon} alt={this.texts['E-dan'].data['5Off'].icon_alt} /></div>
                  <div className="txt"><span>{this.texts['E-dan'].data['5Off'].text}</span></div>
                </div>
                <div tabIndex={0}>
                  {this.texts['E-dan'].data['125Off'].tooltip
                    ? <div className="tooltiptext"><span>{this.texts['E-dan'].data['125Off'].tooltip}</span></div>
                    : null
                  }
                  <div className="bgImg">
                    <img src={this.texts['E-dan'].data['125Off'].icon} alt={this.texts['E-dan'].data['125Off'].icon_alt} /></div>
                  <div className="txt"><span>{this.texts['E-dan'].data['125Off'].text}</span></div>
                </div>
                <div tabIndex={0}>
                  {this.texts['E-dan'].data.earlyCheckIn.tooltip
                    ? <div className="tooltiptext"><span>{this.texts['E-dan'].data.earlyCheckIn.tooltip}</span></div>
                    : null
                  }
                  <div className="bgImg">
                    <img src={this.texts['E-dan'].data.earlyCheckIn.icon} alt={this.texts['E-dan'].data.earlyCheckIn.icon_alt} /></div>
                  <div className="txt"><span>{this.texts['E-dan'].data.earlyCheckIn.text}</span></div>
                </div>
              </div>
              <div className="iconsRow">
                <div tabIndex={0}>
                  {this.texts['E-dan'].data.eGift.tooltip
                    ? <div className="tooltiptext"><span>{this.texts['E-dan'].data.eGift.tooltip}</span></div>
                    : null
                  }
                  <div className="bgImg">
                    <img src={this.texts['E-dan'].data.eGift.icon} alt={this.texts['E-dan'].data.eGift.icon_alt} /></div>
                  <div className="txt"><span>{this.texts['E-dan'].data.eGift.text}</span></div>
                </div>
                <div tabIndex={0}>
                  {this.texts['E-dan'].data.freeNights.tooltip
                    ? <div className="tooltiptext"><span>{this.texts['E-dan'].data.freeNights.tooltip}</span></div>
                    : null
                  }
                  <div className="bgImg">
                    <img src={this.texts['E-dan'].data.freeNights.icon} alt={this.texts['E-dan'].data.freeNights.icon_alt} /></div>
                  <div className="txt"><span>{this.texts['E-dan'].data.freeNights.text}</span></div>
                </div>
                <div tabIndex={0}>
                  {this.texts['E-dan'].data.bestRoom.tooltip
                    ? <div className="tooltiptext"><span>{this.texts['E-dan'].data.bestRoom.tooltip}</span></div>
                    : null
                  }
                  <div className="bgImg">
                    <img src={this.texts['E-dan'].data.bestRoom.icon} alt={this.texts['E-dan'].data.bestRoom.icon_alt} /></div>
                  <div className="txt"><span>{this.texts['E-dan'].data.bestRoom.text}</span></div>
                </div>
              </div>
            { this.texts['E-dan'].data.Item7 ?
            <div className="iconsRow">
              <div tabIndex={0}>
                  {this.texts['E-dan'].data.Item7.tooltip
                    ? <div className="tooltiptext"><span>{this.texts['E-dan'].data.Item7.tooltip}</span></div>
                  : null
                }
                <div className="bgImg">
                    <img src={this.texts['E-dan'].data.Item7.icon} alt={this.texts['E-dan'].data.Item7.icon_alt} /></div>
                  <div className="txt"><span>{this.texts['E-dan'].data.Item7.text}</span></div>
              </div>
              <div tabIndex={0}>
                  {this.texts['E-dan'].data.Item8.tooltip
                    ? <div className="tooltiptext"><span>{this.texts['E-dan'].data.Item8.tooltip}</span></div>
                  : null
                }
                <div className="bgImg">
                    <img src={this.texts['E-dan'].data.Item8.icon} alt={this.texts['E-dan'].data.Item8.icon_alt} /></div>
                  <div className="txt"><span>{this.texts['E-dan'].data.Item8.text}</span></div>
              </div>
              <div tabIndex={0}>
                  {this.texts['E-dan'].data.Item9.tooltip
                    ? <div className="tooltiptext"><span>{this.texts['E-dan'].data.Item9.tooltip}</span></div>
                  : null
                }
                <div className="bgImg">
                    <img src={this.texts['E-dan'].data.Item9.icon} alt={this.texts['E-dan'].data.Item9.icon_alt} /></div>
                  <div className="txt"><span>{this.texts['E-dan'].data.Item9.text}</span></div>
              </div>
            </div>:null}
            </div>
          </div>
			</div>
		);
	}
}